import React from "react";
import call from "../assets/icons/phone.gif";
import enquiry from "../assets/icons/message.gif";

import location from "../assets/icons/location.gif";
const BottomButtons = () => {
  return (
    <div>
      <div
        className=" flex flex-col md:flex-row justify-between items-center md:px-10 xl:px-20 py-5 md:py-10 bg-[#AA9779] font-playfair font-semibold gap-5"
        // className="left-[20px] md:left-[92vw]"
        // style={{ position: "fixed", bottom: "20px",left:"5px", zIndex: 30 }}
      >
        <a href="tel:8510850101" target="_blank" rel="call us bottom">
          <p className="bg-white flex text-center items-center gap-2 md:gap-5 w-80 md:w-60 xl:w-80">
            <img
              title="Call us"
              src={call}
              alt="WhatsApp"
              style={{
                // width: "50px",
                height: "50px",
                // borderRadius: "50%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              }}
            />{" "}
            Call Us -<span className="font-times">8510850101</span>
          </p>
        </a>
        <a href="mailto:info@tsgoc.in" target="_blank" rel="mail bottom">
          <p className="bg-white flex text-center items-center gap-2 md:gap-5 w-80 md:w-60 xl:w-80">
            <img
              title="Mail Us"
              src={enquiry}
              alt="CallUs"
              style={{
                // width: "50px",
                height: "50px",
                // borderRadius: "50%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              }}
            />{" "}
            Mail Us -<span className="font-times">
            info@tsgoc.in</span>
          </p>
        </a>
        <a href="https://www.google.com/maps?q=30.968885,77.228214" target="_blank" rel="noopener noreferrer" aria-label="Google Location">
          <p className="bg-white flex text-center items-center gap-2 md:gap-5 w-80 md:w-60 xl:w-80">
            <img
              className="hover:cursor-pointer"
              title="Location"
              src={location}
              alt="google location"
              style={{
                // width: "50px",
                height: "50px",
                // borderRadius: "50%",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
              }}
            />
            Locate Us-<span className="font-times">Chail, Himachal</span>
          </p>
        </a>
      </div>
    </div>
  );
};

export default BottomButtons;
