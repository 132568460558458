import React from "react";

const Banner = () => {
  return (
    <div>
     <video
        className="w-full h-auto xl:h-[700px] object-cover"
        autoPlay
        loop
        muted
        playsInline
        controls
        poster="/bg_video/bg2_video.jpg"
      >
        <source
          // src="https://res.cloudinary.com/dpkvvpwv6/video/upload/v1718865211/Indus%20VAlley%20Mukteshwar/Banners/IndusvalleyBanner2_eqcqzx.mp4"
          src="https://res.cloudinary.com/dodlkc2b8/video/upload/v1720006491/ChailBanner2_jlovxf.mp4"
          type="video/mp4"
        />
        {/* Add additional source elements for other video formats if needed */}
      </video>
      {/* <div className="relative z-20 flex items-center justify-center h-full bg-black bg-opacity-50">
        <h2 className="text-white text-3xl md:text-7xl tracking-wider font-french-canon text-center">
        Hillcrest Café
        </h2>
      </div> */}
    </div>
  );
};

export default Banner;
