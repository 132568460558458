import React, { useState } from "react";
import camera from "../assets/icons/camera_icon.png";
import location from "../assets/icons/location_icon.png";
import highways from "../assets/icons/highway_icon.png";
import airports from "../assets/icons/airport_icon.png";
import railway from "../assets/icons/railway_icon.png";
import Map from "../assets/photos/chailMap-layout.png"

const NearBy2 = () => {
  const [activeSection, setActiveSection] = useState(0); // Initially set the first section to be active

  const toggleSection = (index) => {
    setActiveSection(activeSection === index ? -1 : index); // Toggle active section
  };

  const sectionData = [
    {
      title: "Nearby Attractions",
      icon: camera,
      content: [
        { name: "Chail Wildlife Sanctuary", distance: "63 kms" },
        { name: "Kali ka Tibba", distance: "7 Kms" },
        { name: "Sidh Baba ka Mandir", distance: "5.8 Kms" },
      ],
    },
    {
      title: "Key Distances",
      icon: location,
      content: [
        { name: "Shimla", distance: "46 Kms (1 Hours)" },
        { name: "Chandigarh", distance: "106 Kms (2.5 Hours)" },
        { name: "Dehradun", distance: "218 Kms (4 Hours)" },
        { name: "Punjab", distance: "283 Kms (5 Hours)" },
        { name: "Amritsar", distance: "329 Kms (6 Hours)" },
        { name: "Delhi", distance: "337 Kms (6.5 Hours)" },
      ],
    },
    {
      title: "Highways",
      icon: highways,
      content: [
        { name: "Shimla (HP SH 1)", distance: "28 Kms" },
        { name: "Sirmour Solan (HP SH 2)", distance: "78 Kms" },
        { name: "Hamirpur Kangra (HP SH 39)", distance: "59.6 Kms" },
      ],
    },
    {
      title: "Railway & Metro",
      icon: railway,
      content: [
        { name: "Kalka Railway Station ", distance: "81 Kms" },
      ],
    },
    {
      title: "Airports",
      icon: airports,
      content: [
        { name: "Simla (SLV)", distance: "60.6 Kms (1.58 Hours)" },
        {name: "Chandigarh Airport", distance: "117 Kms" }
      ],
    },
  ];


  // bg-[#9CA898] bg-opacity-40
  return (
    <div className=" m-3 md:m-10 mb-0 md:mb-0 rounded-2xl">
      <div className="contact_map row-fluid no-padd margin-lg-115t margin-sm-50t no-padd relative box-border flex flex-col">
        <h3 className="flex font-playfair text-3xl md:text-5xl xl:text-6xl text-[#FFFFF0] xl:text-[#2D3A1B] py-5 items-center text-center mx-auto gap-2 md:gap-5 xl:w-2/3">
          Perfect Location for Your Nature Home
        </h3>
        
        <div className="flex flex-col gap-5 md:gap-10 xl:flex-row justify-center items-center p-3 md:p-10 text-white xl:text-black mx-auto">
          <div className="google-maps flex flex-col gap-5 w-full md:w-2/3 xl:w-1/3 ">
            <h3 className="font-playfair text-2xl md:text-3xl xl:text-4xl text-center text-[#FFFFF0] xl:text-[#2D3A1B] gap-2 md:gap-5">
              Nearby Locations
            </h3>

            {sectionData.map((section, index) => (
              <div key={index} className="section-container w-fit flex flex-col border-b border-gray-300">
                <button
                  className="section-title flex text-center items-center gap-3 w-80 md:w-60 xl:w-80 text-lg md:text-xl xl:text-2xl"
                  onClick={() => toggleSection(index)}
                  aria-expanded={activeSection === index}
                >
                  <img
                    className="bg-white xl:bg-transparent"
                    title={section.title}
                    src={section.icon}
                    alt={section.title}
                    style={{
                      height: "50px",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  {section.title}
                  <span className="ml-auto">
                    {activeSection === index ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform rotate-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 15l7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 transform rotate-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    ) }
                  </span>
                </button>
                {activeSection === index && (
                  <div className="section-content">
                    {section.content.map((item, idx) => (
                      <ol key={idx} className="text-sm md:text-base flex pt-4 justify-between w-80">
                        <li>{item.name}</li>
                        <li>{item.distance}</li>
                      </ol>
                    ))}
                  </div>
                )}
              </div>
            ))}

          </div>
          <div className="w-full  xl:w-1/2 flex flex-col gap-5 bg-[#DFE6E0] bg-opacity-70 xl:bg-opacity-0">
        <img src={Map} alt="collection of pics" />
      </div>
        
        </div>
  {/* Google Location */}
          <div className="google-maps w-full flex flex-col gap-5">
            <h3 className="font-playfair text-2xl md:text-4xl xl:text-5xl text-[#FFFFF0] xl:text-[#2D3A1B] text-center gap-2 md:gap-5">
              Google Location
            </h3>
         

          </div>

      </div>
    </div>
  );
};

export default NearBy2;
