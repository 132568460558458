import React, { useState, useEffect } from "react";
// import { TypeAnimation } from 'react-type-animation';
// import Banner_1 from "../assets/photos/cottage1/banner2.jpg";
import Banner_1 from "../assets/bg/contact-banner.jpg";
import BottomButtons from "./BottomButtons";

// import { Link } from "react-router-dom";

const imageUrls = [Banner_1];

const BannerContact = () => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 3000); // Change background every 5 seconds (adjust as needed)

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div
        className="h-[50vw] md:h-[30vw] bg-cover bg-center flex justify-center items-center p-5"
        style={{ backgroundImage: `url(${imageUrls[backgroundIndex]})` }}
      >
        <h1 className=" text-white text-2xl md:text-6xl font-playfair p-2 text-center md:p-4 rounded-xl ">
          Reach Us
        </h1>
      </div>
<BottomButtons/>
    
    </>
  );
};

export default BannerContact;
