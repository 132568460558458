import React from 'react'
import BannerContact from './BannerContact'
import ContactDetails from './ContactDetails'

const Contact = () => {
  return (
    <div className='bg-[#B4AEA0]'>
        <BannerContact/>
        <ContactDetails/>
      
    </div>
  )
}

export default Contact
