import React, { useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";

import bg from "./assets/bg/home_bg.jpg";
// import AboutUs from "./components/AboutUs";
import NearBy2 from "./components/NearBy2";
import collage from "./assets/bg/cc.webp";
import Banner from "./components/Banner";
import AboutUs2 from "./components/AboutUs2";

// import GalleryCarousel from "./components/GalleryCarousel";


const Home = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: containerRef.current,
      smooth: true,
    });

    return () => {
      scroll.destroy();
    };
  }, []);
  return (
    <div
      ref={containerRef}
      className="min-h-screen pb-20"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    >
      <div className="bg-[#9CA898] bg-opacity-40 xl:bg-opacity-0">
             <Banner/>
      <AboutUs2 />
{/* <GalleryCarousel/> */}
      <div className="mx-3 md:mx-10 rounded-3xl">
        <img src={collage} alt="collection of pics" />
      </div>
      <NearBy2 />
      </div>
 
    </div>
  );
};

export default Home;
