import React from 'react'
import pic from "../assets/bg/contact_pic.png"
const ContactDetails = () => {
  return (
    <div className='flex flex-col md:flex-row-reverse gap-5 md:gap-14 px-3 md:p-10 md:pb-0 xl:p-20 justify-center xl:gap-20 pt-10  xl:px-24'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.8281951735616!2d77.22821431449348!3d30.968885381764154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDU4JzA4LjAiTiA3N8KwMTMnNTAuOCJF!5e0!3m2!1sen!2sin!4v1655300287058!5m2!1sen!2sin"
              className="w-full md:w-1/2 h-60 md:h-[400px] border-0"
              allowFullScreen=""
              loading="lazy"
              title="Google Map of Poetry By The Hills"
              aria-label="Google Map of Poetry By The Hills"
            ></iframe>
        <div className='w-full md:w-1/2 gap-10 md:pt-5 flex flex-col justify-center items-center'>
               <h1 className='text-3xl md:text-4xl xl:text-6xl text-center font-playfair text-[#2D3A1B]'>Get In Touch</h1>
       <iframe className="text-3xl md:text-4xl w-full h-80 md:pl-2 text-white rounded-2xl font-semibold font-serif"
        title="crm-form"
        id="Contact_Form"
        src="https://account.solidperformers.com/capture_form_data/MTI0Mg=="
        border="0"

      ></iframe>
        </div>
       
     
    </div>
  )
}

export default ContactDetails
