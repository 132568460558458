import React, { useState } from "react";
// import bg from "../assets/bg/About_bg.jpg";
import bg2 from "../assets/bg/home_bg.jpg";
import bird from "../assets/bg/bird-2.png"
import wifi from "../assets/amenities-icons/wifi.png";
import kids from "../assets/amenities-icons/kids.png";
import garden from "../assets/amenities-icons/garden.png";
import gym from "../assets/amenities-icons/gym.png";
import parking from "../assets/amenities-icons/parking.png";
import himalayan from "../assets/amenities-icons/hill view.png"

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  const bgStyle = {
    backgroundImage: `url(${bg2})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center middle",
  };

  const sectionClass =
    "flex flex-col gap-5 justify-center md:p-5 rounded-2xl p-3";

  return (
    <div className=" flex flex-col gap-10  md:pt-0 " style={bgStyle}>
      <div className="bg-[#9CA898] bg-opacity-60 xl:bg-opacity-0  md:p-10">
         <div className="rounded-2xl ">
        <div className={sectionClass} >
          <div className="flex flex-col xl:flex-row font-playfair text-xl md:text-3xl xl:text-4xl justify-end md:gap-10 text-[#FFFFF0] xl:text-[#2D3A1B] items-center text-center gap-2">
            <h1>About Chail Heights Villas</h1><img className="h-24 xl:h-40 xl:w-[400px] xl:relative right-10 xl:ml-16" src={bird} alt="bird"/>
          </div>
          <div className="font-quicksand text-white xl:text-black text-base md:text-lg xl:text-xl flex flex-col gap-2 md:gap-3 w-full">
                   <p className="md:text-justify">
              We are thrilled to present{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                Chail Heights Villas
              </span>
              , an exceptional residential community crafted by{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                The Shubham Group.
              </span>{" "}
              Located in the scenic landscapes of{" "}
              <span className="font-semibold xl:text-neutral-500">
                Chail, Himachal Pradesh,
              </span>{" "}
              near the iconic{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                Chail Palace
              </span>
              , this exclusive gated society spans 8 acres. It features{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                13 independent villas
              </span>{" "}
              in Chail, each boasting a generous
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                {" "}
                (2273 Sq. Ft built-up area).
              </span>{" "}
              Complementing these elegant villas are{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                45 1BHK ultra luxurious apartments
              </span>{" "}
              each spanning{" "}
              <span className="font-semibold text-[#EBE1B3] xl:text-neutral-500">
                (775 SQ.Ft)
              </span>
              .
            </p>

            {showMore && (
              <>
                <p className="md:text-justify">
                  Chail Heights Villas redefines residential living in Chail,
                  Himachal Pradesh, offering a blend of modern comforts and
                  natural beauty. Each villa in Chail, Himachal Pradesh,
                  combines comfort and sophistication, making it perfect for
                  those looking for a peaceful yet upscale lifestyle. Our 1 BHK
                  apartments in Chail are designed with style and practicality,
                  appealing to those with refined preferences.
                </p>

                       <p className="md:text-justify">
                  The Shubham Group, renowned for delivering high-quality
                  residential projects in Uttarakhand and Himachal Pradesh,
                  proudly adds this project to its impressive portfolio. With a
                  proven track record of excellence, our properties in Chail,
                  Himachal, are a testament to our dedication to creating homes
                  that offer unparalleled living experiences.
                </p>

                       <p className="md:text-justify">
                  Whether you are looking for a villa, a cottage, or a luxury
                  apartment in Chail, Himachal, Chail Heights promises to meet
                  your expectations. Our properties in Chail provide the perfect
                  retreat, offering a peaceful escape from the hustle and bustle
                  of city life. These cottages in Himachal are not just houses;
                  they are homes designed to provide comfort, luxury, and a
                  connection to nature.
                </p>

                       <p className="md:text-justify">
                  Explore the finest houses and flats in Chail with Chail
                  Heights Villas. Invest in a property in Chail, Himachal, and
                  experience the best that this beautiful region has to offer.
                </p>
              </>
            )}

            {/* Toggle Read More/Show Less Button */}
            
            <button
              className="text-[#FFFFF0] xl:text-[#2D3A1B] hover:underline cursor-pointer"
              onClick={toggleReadMore}
            >
              {showMore ? "Show Less" : "Read More"}
            </button>
            <div className="flex flex-col xl:flex-row font-playfair text-xl md:text-3xl xl:text-4xl justify-center md:gap-10 text-[#FFFFF0] xl:text-[#2D3A1B] items-center text-center gap-2">
       
              <p className="text-[#FFFFF0] xl:text-[#2D3A1B] text-center text-xl md:text-3xl xl:text-4xl py-5 font-playfair ">
              Amenities and necessities provided in the Project
            </p>  
            </div>
           
            <ol className="grid grid-cols-2 md:grid-cols-3 gap-3 px-5 md:py-5 w-full justify-center text-[#FFFFF0] xl:text-[#2D3A1B] text-lg md:text-xl xl:text-2xl font-playfair">
            <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={parking}
                  alt="TRAVEL DESK"
                />{" "}
                Parking lot
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={himalayan}
                  alt="WARDROBE"
                />{" "}
               Paranomic Himalayan View
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16 "
                  src={kids}
                  alt="WARDROBE"
                />{" "}
                Play area for kids
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={garden}
                  alt="parking"
                />{" "}
                Common garden
              </li>
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16 "
                  src={gym}
                  alt="HOUSE KEEPING"
                />{" "}
                Gymnasium
              </li>
           
              <li className="flex flex-col md:hover:scale-105 duration-500 items-center p-2 rounded-xl text-center gap-3">
                <img
                  className="h-16"
                  src={wifi}
                  alt="WARDROBE"
                />{" "}
                Wifi enabled area
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="rounded-2xl">
        <div className={sectionClass}>
          <div className="flex flex-col xl:flex-row justify-end font-playfair text-xl md:text-3xl xl:text-4xl text-[#FFFFF0] xl:text-[#2D3A1B] items-center gap-2 md:gap-5">
            <h2>Living in Chail, Himanchal Pradesh</h2><img className="h-24 xl:h-40 xl:w-[400px] xl:relative right-10" src={bird} alt="bird"/>
          </div>
          <div className="font-quicksand text-white xl:text-black text-base md:text-lg xl:text-xl flex flex-col gap-2 md:gap-3 w-full">
                   <p className="md:text-justify">
              Chail offers a unique blend of natural beauty, peace, and cultural
              richness. Situated amidst the Himalayan foothills, Chail is an
              undiscovered spot with a low population, located at a distance of
              63 kms from the Chail Wildlife Sanctuary. Away from the hustle and
              bustle of Shimla and Solan, Chail enjoys an amazing weather
              year-round and offers mesmerizing views of the Himalayas and the
              valley.
            </p>

            {showMore && (
              <>
                       <p className="md:text-justify">
                  Chail is renowned for its picturesque landscapes, lush
                  forests, and pleasant climate throughout the year. The serene
                  environment provides residents with clean air, breathtaking
                  mountain views, and a sense of calm that is hard to find
                  elsewhere. Outdoor enthusiasts in Chail have ample
                  opportunities for activities such as trekking, nature walks,
                  bird watching, and camping in the nearby forests, thanks to
                  the pleasant climate that makes outdoor activities enjoyable
                  year-round.
                </p>

                       <p className="md:text-justify">
                  The town boasts a rich cultural heritage with historical
                  landmarks like the Chail Palace and the Chail Cricket Ground,
                  the highest cricket ground in the world. These landmarks add
                  to the charm and historical significance of the region.
                  Whether you seek a cottage or apartment in Chail, Himachal
                  Pradesh, you'll find residences that blend seamlessly into the
                  natural beauty of the area.
                </p>
                       <p className="md:text-justify">
                  The tight-knit community in Chail fosters a warm and welcoming
                  atmosphere, with residents actively participating in local
                  festivals, cultural events, and community gatherings,
                  fostering a strong sense of belonging and camaraderie. Chail
                  Heights also offers flats and cottages in Chail, Himachal
                  Pradesh, providing options for those looking to experience
                  luxury living or a cozy retreat amidst the hills.
                </p>
                       <p className="md:text-justify">
                  Despite its serene environment, Chail offers modern amenities
                  including good connectivity with Wi-Fi zones, and recreational
                  facilities such as clubs, gyms, and parks, enhancing the
                  overall quality of life. Whether you prefer a house or an
                  apartment in Chail, Himachal Pradesh, the town is an ideal
                  destination for those seeking a peaceful and fulfilling
                  lifestyle amidst the Himalayas.
                </p>
                       <p className="md:text-justify">
                  Invest in a property at moderate rates in Chail, Himachal
                  Pradesh, and enjoy the beauty of this undiscovered gem.
                  Experience living amidst the Himalayas with easy access to
                  Shimla (46 kms, 1 hour drive), Chandigarh (106 kms, 2.5 hours
                  drive), Kalka Railway Station (81 kms), and Simla Airport
                  (60.6 kms, 1.58 hours drive).
                </p>
              </>
            )}
            {/* Toggle Read More/Show Less Button */}
            <button
              className="text-[#FFFFF0] xl:text-[#2D3A1B] hover:underline cursor-pointer"
              onClick={toggleReadMore}
            >
              {showMore ? "Show Less" : "Read More"}
            </button>
          </div>
        </div>
      </div>
      </div>
     
    </div>
  );
};

export default AboutUs;
