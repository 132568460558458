import React, { useState, useEffect, useRef } from "react";
import LocomotiveScroll from "locomotive-scroll";

import bg from "../assets/bg/home_bg.jpg";
import img1 from "../assets/photos/chail1.webp";
import img2 from "../assets/photos/chail2.webp";
import img3 from "../assets/photos/chail3.webp";
import img4 from "../assets/photos/chail4.webp";
import img5 from "../assets/photos/chail5.webp";
import img6 from "../assets/photos/chail6.webp";

const images = [
  { src: img1, alt: "Cottage for stay in mukteshwar" },
  { src: img2, alt: "Villa for stay in nainital" },
  { src: img3, alt: "Gallery Image 3" },
  { src: img4, alt: "Gallery Image 4" },
  { src: img5, alt: "Gallery Image 5" },
  { src: img6, alt: "Gallery Image 6" },
  // ... add more paths as needed
];

function Gallery2() {
  const containerRef = useRef(null);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: containerRef.current,
      smooth: true,
    });

    return () => {
      scroll.destroy();
    };
  }, []);

  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openFullscreen = (image, index) => {
    setFullscreenImage(image.src);
    setCurrentIndex(index);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  const showNextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
    setFullscreenImage(images[nextIndex].src);
  };

  const showPrevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(prevIndex);
    setFullscreenImage(images[prevIndex].src);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (fullscreenImage) {
        if (event.key === 'ArrowRight') {
          showNextImage();
        } else if (event.key === 'ArrowLeft') {
          showPrevImage();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [fullscreenImage, currentIndex]);

  return (
    <div 
    ref={containerRef}
    className="min-h-screen pb-20 flex flex-col justify-center items-center"
    style={{
      backgroundImage: `url(${bg})`,
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center top",
    }}>
      <div  className="flex flex-col justify-center items-center"
      >
        <h3 className="font-playfair text-3xl md:text-5xl xl:text-6xl text-[#FFFFF0] xl:text-[#2D3A1B] pt-5 md:pt-20 items-center text-center gap-2 md:gap-5">
          Gallery
        </h3>
      </div>

      <section className="flex flex-col md:flex-row md:p-5 md:pb-0 lg:px-10 lg:pb-0">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-20 p-5 pb-0 w-full bg-cover mx-auto py-10 md:pb-20">
          {images.map((image, index) => (
            <img
              key={index}
              className="shadow-md border border-black p-1 shadow-amber-50 gap-5 md:hover:shadow-[#74613C] md:hover:shadow-lg lg:hover:scale-110 md:duration-700 cursor-pointer"
              src={image.src}
              alt={image.alt}
              onClick={() => openFullscreen(image, index)}
            />
          ))}
        </div>
      </section>

      {fullscreenImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex justify-center items-center">
          <button
            className="absolute bottom-28 left-20 text-black bg-white font-semibold text-sm md:text-xl font-serif m-3 p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            onClick={showPrevImage}
          >
            Previous
          </button>

          <button
            className="absolute bottom-28 right-20 text-black bg-white font-semibold text-sm md:text-xl font-serif m-3 p-3 md:p-4 my-auto md:mr-8 md:px-8 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            onClick={showNextImage}
          >
            Next
          </button>

          <img
            src={fullscreenImage}
            alt="Fullscreen Img"
            className="max-h-full max-w-full cursor-pointer md:p-10 md:pt-32"
            onClick={closeFullscreen}
          />
        </div>
      )}
    </div>
  );
}

export default Gallery2;
